@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leftSection{background-color:#282c34;min-height:60vh;width:30vw;min-width:275px;display:flex;flex-direction:column;align-items:center;justify-content:flex-start;font-size:calc(2px + 2vmin);color:white;border-radius:8px 0 0 8px}.leftSection h1{white-space:nowrap;color:#fce8cc;word-spacing:2px;padding:2px}@media (max-width: 600px){.leftSection{flex-direction:column;min-height:10vh;width:100%;border-radius:8px 8px 0 0}.leftSection h1{font-size:calc(10px + 2vmin)}}.contactSection{width:90%;height:80%;background-color:#282c34;display:flex;flex-direction:column}.contactSection img{box-shadow:0 0 2em #060606;width:50px;background-color:#282c34;padding:6px;border-radius:15px;margin-left:-30px}@media (max-width: 600px){.contactSection img{width:35px}}.contactSection p{font-family:'Dancing Script';color:#866a55;margin:auto;font-weight:800;font-size:calc(10px + 2vmin);padding:15px;text-overflow:ellipsis}.rigthSection{background-color:#282c34;min-height:60vh;width:70vw;min-width:275px;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(2px + 2vmin);color:white;border-radius:0 8px 8px 0}.rigthSection img{position:absolute;width:600px;transform:rotate(90deg) scale(1.06);-webkit-filter:drop-shadow(28px -22px 12px #0e0e0e);filter:drop-shadow(28px -22px 12px #0e0e0e);border-radius:15px;transition:400ms;animation:fadein 2s}.rigthSection img:hover{transform:rotate(90deg) scale(1.1) translateX(-10px) translateY(10px);-webkit-filter:drop-shadow(28px -22px 12px #777373);filter:drop-shadow(28px -22px 12px #777373)}@media (max-width: 600px){.rigthSection{border-radius:0 0 8px 8px}.rigthSection img{width:400px}}.cardCentral{display:flex;max-width:60vw;margin:auto}@media (max-width: 600px){.cardCentral{flex-direction:column;max-width:70vw}}.cardGeneral{min-height:100vh;background-color:#fce8cc;display:flex}@keyframes fadein{from{opacity:0}to{opacity:1}}

