@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');


.leftSection {
  background-color: #282c34;
  min-height: 60vh;
  width: 30vw;
  min-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(2px + 2vmin);
  color: white;
  border-radius: 8px 0 0 8px;
  h1{
    white-space: nowrap;
    color: #fce8cc;
    word-spacing: 2px;
    padding: 2px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    min-height: 10vh;
    width: 100%;
    border-radius: 8px 8px 0 0;
    h1{
      font-size: calc(10px + 2vmin);
    }
    }
}

.contactSection {
  width: 90%;
  height: 80%;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  img{
    box-shadow: 0 0 2em #060606;
    width: 50px;
    background-color: #282c34;
    padding: 6px;
    border-radius: 15px;
    margin-left: -30px;
    @media (max-width: 600px) {
      width: 35px;
    }
  }
  p{
    font-family: 'Dancing Script';
    color: rgb(134, 106, 85);
    margin: auto;
    font-weight: 800;
    font-size: calc(10px + 2vmin);
    padding: 15px;
    text-overflow: ellipsis;
  }
}

.rigthSection {
  background-color: #282c34;
  min-height: 60vh;
  width: 70vw;
  min-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
  border-radius: 0 8px 8px 0 ;
  img{
    position: absolute;
    width: 600px;
    transform: rotate(90deg) scale(1.06);
    // box-shadow: 0 0 2em #060606;
    filter: drop-shadow(28px -22px 12px rgb(14, 14, 14));
    border-radius: 15px;
    transition: 400ms;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
    &:hover{
      transform: rotate(90deg) scale(1.1) translateX(-10px) translateY(10px);
      filter: drop-shadow(28px -22px 12px rgb(119, 115, 115));
    }
  }

  @media (max-width: 600px) {
    border-radius: 0 0 8px 8px;
    img{
      width: 400px;
    }
    }
}

.cardCentral {
  display: flex;
  max-width: 60vw;
  margin: auto;
  @media (max-width: 600px) {
  flex-direction: column;
  max-width: 70vw;
  }
}

.cardGeneral {
  min-height: 100vh;
  background-color: #fce8cc;
  display: flex;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
